import React from 'react'
import { Stack } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card, Text } from 'theme-ui'

export default () => {
  
    return (
      <>
        <Seo title='Polityka Prywatnosci' />
        <Divider />
        <Stack effect='fadeInDown'>
          <PageTitle
            header='Polityka Prywatności'
          />
        </Stack>
        <Stack>
        <Card variant='paper'>
          <Text variant='p'>
  <p>Niniejsza Polityka Prywatności ma zastosowanie do danych osobowych użytkowników serwisu internetowego (dalej <b>„Użytkowników”</b>) działającego pod adresem https://wparagrafie.pl/ (dalej <b>„Serwis”</b>). Niniejsza Polityka prywatności wyjaśnia również, jak przetwarzamy Państwa dane osobowe.</p>
  <h2>Administrator danych</h2>
  <p>Administratorem danych osobowych użytkowników jest redakcja Wparagrafie.pl prowadzona przez Magda Kwiatkowska, ul. Włościańska 15, 01-710 Warszawa (<b>„Administrator”</b>).</p>
  <p>Chronimy dane osobowe użytkowników zgodnie z wymogami prawa polskiego, w szczególności zgodnie z ogólnym rozporządzeniem o ochronie danych osobowych RODO (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE).</p>
  <h2>Podstawy i cele przetwarzania danych osobowych użytkowników</h2>
  <p>Zbieramy i przetwarzamy adresy poczty elektronicznej w celu wysyłki newslettera na podstawie zgód udzielonych przez użytkowników. Przechowujemy dane osobowe do momentu wycofania zgód na ich przetwarzanie.</p>
  <p>Użytkownik może w każdym momencie wycofać udzieloną zgodę, klikając w link zamieszczony na dole przesłanego mu newslettera lub pisząc wiadomość na adres redakcja@wparagrafie.pl. Po cofnięciu zgody przez użytkownika dane będą przetwarzane jedynie w celu ich usunięcia.</p>
  <p>Nie przetwarzamy innych danych niż adresy e-mail, w tym szczególnych kategorii danych osobowych.</p>
  <h2>Prawa użytkowników</h2>
  <p>Każdemu użytkownikowi, który wypełnił formularz rejestracyjny czy w inny sposób udostępnił nam swoje dane osobowe, zapewniamy realizację przysługujących mu praw, które w zależności od przypadku mogą obejmować:</p>
  <ul>
      <li>żądanie sprostowania danych osobowych, jeżeli dane osobowe użytkownika są nieprawidłowe lub niekompletne,</li>
      <li>żądanie usunięcia danych osobowych, jeżeli dane osobowe użytkownika nie będą już niezbędne do celów, dla których zostały zebrane, gdy użytkownik cofnie zgodę na przetwarzanie danych, a także gdy dane osobowe są przetwarzane niezgodnie z prawem lub gdy obowiązek ich usunięcia wynika z przepisów prawa,</li>
      <li>żądanie ograniczenia przetwarzania danych, gdy użytkownik, kwestionuje prawidłowość danych osobowych, a także jeżeli dane osobowe są przetwarzane niezgodnie z prawem, ale użytkownik sprzeciwia się ich usunięciu, jak również jeżeli dane osobowe nie są już nam potrzebne, ale mogą być potrzebne użytkownikowi do obrony lub dochodzenia roszczeń,</li>
      <li>cofnięcie udzielonej zgody, ze skutkiem na przyszłość.</li>
      <li>Użytkownikowi przysługuje prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</li>
  </ul>
  <h2>Newsletter</h2>
  <p>Jeżeli chcesz zapisać się do newslettera, musisz przekazać adres e-mail oraz imię za pośrednictwem formularza zapisu do newslettera. Podanie danych jest dobrowolne, ale niezbędne, by zapisać się do newslettera.</p>
  <p>Dane przekazane podczas zapisu do newslettera wykorzystywane są w celu przesyłania newslettera, a podstawą prawną ich przetwarzania jest zgoda użytkownika (art. 6 ust. 1 lit. a RODO) wyrażona podczas zapisywania się do newslettera.</p>
  <p>Dane będą przetwarzane przez czas funkcjonowania newslettera, chyba że wcześniej zrezygnujesz z jego otrzymywania. Rezygnacja z otrzymywania newslettera nie prowadzi jednak do usunięcia danych z bazy - dane nadal będą przechowywane w systemie mailingowym w celu obrony przed ewentualnymi roszczeniami związanymi z przesyłaniem newslettera, w szczególności na potrzeby wykazania udzielenia przez Ciebie zgody na otrzymywani newslettera, co stanowi uzasadniony interes przetwarzającego, o którym mowa w art. 6 ust. 1. lit. f RODO.</p>
  <p>W każdej chwili możesz sprostować swoje dane zapisane w bazie newsletterowej. Przysługuje Ci również prawo do przenoszenia danych, o którym mowa w art. 20 RODO.</p>
  <h2>Komentarze</h2>
  <p>Jeżeli chcesz dodać komentarz na blogu, musisz wypełnić formularz i podać w nim swój adres e-mail oraz imię. Podanie danych jest dobrowolne, ale niezbędne, by dodać komentarz.</p>
  <p>System komentarzy obsługiwany jest przez firmę Disqus Inc., 301 Howard Street, Suite 300, San Francisco, CA94105, USA. Disqus jest samodzielnym administratorem Twoich danych osobowych, a korzystanie z systemu odbywa się na podstawie akceptacji przez Ciebie regulaminu świadczenia usług przez Disqus.</p>
  <p>Dane przekazane podczas dodawania komentarza wykorzystywane są w celu opublikowania komentarza na blogu, a podstawą prawną ich przetwarzania jest Twoja zgoda (art. 6 ust. 1 lit. a RODO) wynikająca z dodania komentarza.</p>
  <p>Dane będą przetwarzane przez czas funkcjonowania komentarzy na blogu, chyba że wcześniej poprosisz o usunięcie komentarza, co spowoduje usunięcie Twoich danych z bazy.</p>
  <p>W każdej chwili możesz sprostować swoje dane przypisane do komentarza, jak również zażądać ich usunięcia. Przysługuje Ci również prawo do przenoszenia danych, o którym mowa w art. 20 RODO.</p>
  <p>Uprawnienia te możesz realizować bezpośrednio w ramach swojego konta użytkownika w systemie Disqus.</p>
  <p>Niedopuszczalne jest zamieszczanie w serwisie treści o charakterze bezprawnym, w szczególności:</p>
  <ul>
      <li>Wulgaryzmów</li>
      <li>Obraźliwych sformułowań</li>
      <li>Treści naruszających prawa autorskie osób trzecich</li>
      <li>Treści naruszających dobra osobiste osób trzecich</li>
  </ul>
  <p>Administrator zastrzega, że nie będzie zamieszczał komentarzy zawierających treści niezgodne z prawem oraz wymogami określonymi w Polityce prywatności.</p>
  <h2>Odbiorcy danych</h2>
  <p>Twoje dane mogą być przetwarzane przez moich podwykonawców, czyli podmiotów, z których usług korzystam przy przetwarzaniu danych oraz świadczeniu na Twoją rzecz usług czy realizacji zamówień.</p>
  <ul>
      <li>Netlify – w celu przechowywania danych osobowych na serwerze, w tym serwerze skrzynki pocztowej,</li>
      <li>Google Ireland Limited – w celu korzystania z usług Google w ramach pakietu G-Suite, w tym poczty e-mail funkcjonującej w oparciu o serwery Google,</li>
      <li>The Rocket Science Group LLC d/b/a MailChimp – w celu korzystania z systemu MailChimp, w którym przetwarzane są Twoje dane osobowe, jeżeli zapiszesz się do newslettera,</li>
      <li>osoby, które przy świadczeniu na moją rzecz usług związanych ze wsparciem technicznym strony internetowej mogą potencjalnie uzyskać dostęp do Twoich danych osobowych.</li>
  </ul>
  <p>Wszystkie podmioty, którym powierzam przetwarzanie danych osobowych gwarantują stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych wymaganych przez przepisy prawa.</p>
  <p>Z uwagi na korzystanie z systemu MailChimp, Twoje dane mogą być przekazywane do Stanów Zjednoczonych Ameryki (USA) w związku z ich przechowywaniem na amerykańskich serwerach. Dostawca systemu MailChimp korzysta z odpowiednich mechanizmów zgodności takich jak standardowe klauzule umowne. Ponadto, w tym zakresie mowa wyłącznie o Twoim adresie e-mail, imieniu i adresie IP.</p>
  <h2>Zakres udostępniania informacji o użytkowniku</h2>
  <p>Nie przekazujemy, nie sprzedajemy i nie użyczamy zgromadzonych danych osobowych użytkowników innym osobom lub instytucjom, chyba że dzieje się to za wyraźną zgodą lub na życzenie użytkownika, lub też na żądanie uprawnionych na podstawie prawa organów państwa na potrzeby prowadzonych przez nie postępowań.</p>
  <h2>Informacja dotycząca plików cookies</h2>
  <p>Serwis wykorzystuje pliki cookies (tzw. „ciasteczka”), aby zapewnić lepsze korzystanie ze strony internetowej. W celu świadczenia usług na najwyższym poziomie W paragrafie zapewnia, że stosowane cookies są bezpieczne dla Użytkowników. Pliki cookies stosowane są również celach statystycznych.</p>
  <p>Pliki cookies są to pliki zapisywane na urządzeniu końcowym Użytkownika, służące do identyfikacji przeglądarki Użytkownika w trakcie korzystania z serwisów. Pliki cookies używane są w celu dostosowania zawartości stron internetowych do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych. Używane są również w celu tworzenia anonimowych, zagregowanych statystyk, które pomagają zrozumieć w jaki sposób użytkownik korzysta ze stron internetowych co umożliwia ulepszanie ich struktury i zawartości, z wyłączeniem personalnej identyfikacji użytkownika.</p>
  <p>Cookies zawierają różne informacje o użytkowniku danej strony WWW i historii jego łączności ze stroną. Dzięki nim właściciel serwera, który wysłał cookies, może poznać adres IP użytkownika, a także na przykład sprawdzić, jakie strony przeglądał on przed wejściem na jego witrynę. Ponadto właściciel serwera może sprawdzić, jakiej przeglądarki używa użytkownik i czy nie nastąpiły informacje o błędach podczas wyświetlania strony. Warto jednak zaznaczyć, że dane te nie są kojarzone z konkretnymi osobami przeglądającymi strony (Użytkownik anonimowy), bez podawania imienia i nazwiska Użytkownika, a jedynie z komputerem połączonym z internetem, na którym cookies zostało zapisane (służy do tego adres IP). Dodatkowo, takie dane są zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.</p>
  <p>Wyróżniamy dwa typy plików cookies, a mianowicie wydajnościowe (zbierające informacje na temat sposobu korzystania z Serwisu przez jego użytkowników) oraz funkcjonalne (zapisujące ustawienia użytkownika). Korzystanie z Serwisu bez zmiany ustawień przeglądarki powoduje, że pliki cookies będą umieszczone w Państwa urządzeniu. Brak zmian tych ustawień oznacza akceptację dla stosowania plików cookies.</p>
  <p>Więcej informacji na temat plików Cookies dostępnych jest w menu pomocy każdej przeglądarki internetowej, takich jak między innymi: Mozilla Firefox, Internet Explorer, Google Chrome, Opera oraz Safari.</p>
  <p>Wyłączenie stosowania cookies może spowodować utrudnienia korzystanie z niektórych usług w ramach witryn Administratora, w szczególności wymagających logowania. Wyłączenie opcji przyjmowania cookies nie powoduje natomiast braku możliwości czytania lub oglądania treści zamieszczanych w witrynach z zastrzeżeniem tych, do których dostęp wymaga logowania.</p>
  <p>Serwis używa wtyczek i innych narzędzi społecznościowych udostępnianych przez serwisy społecznościowe, takie jak Facebook, Twitter, Instagram, Google, LinkedIN.</p>
  <h2>Google Analytics</h2>
  <p>Serwis korzysta z narzędzia Google Analytics zapewnianego przez Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA w celu tworzenia statystyk i ich analizie w celu optymalizacji strony internetowej.</p>
  <p>Jeżeli jesteś zainteresowany szczegółami związanymi z przetwarzaniem danych w ramach Google Analytics, zachęcamy do zapoznania się z wyjaśnieniami przygotowanymi przez Google: https://support.google.com/analytics/answer/6004245.</p>
  <h2>Dane kontaktowe</h2>
  <p>W przypadku pytań lub wątpliwości zapraszamy do kontaktu drogą elektroniczną pod adresem redakcja@wparagrafie.pl</p>
  <h2>Zmiany i aktualizacja Polityki prywatności</h2>
  <p>Zastrzegamy sobie prawo aktualizowania niniejszej Polityki prywatności. Jeżeli wprowadzimy zmiany do naszej Polityki prywatności, zamieścimy zmienioną Politykę prywatności na blogu.</p>


         </Text>
        </Card>
        </Stack>
      </>
    )
  }